import { useSelector, useDispatch } from 'react-redux';
import { setUser, initialState } from 'store/auth/userSlice';
import { apiGetMe, apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';

function useAuth() {
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const query = useQuery();

	const { token, signedIn } = useSelector((state) => state.auth.session);
	const autUser = useSelector((state) => state.auth.user);

	const signIn = async (values) => {
		try {
			const resp = await apiSignIn(values);
			console.log(resp.data.user);
			if (resp.data) {
				const { token } = resp.data;
				dispatch(onSignInSuccess(token));
				if (resp.data.user) {
					dispatch(
						setUser(
							resp.data.user || {
								avatar: '',
								userName: 'Anonymous',
								authority: ['USER'],
								email: '',
							}
						)
					);
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY);
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
				return {
					status: 'success',
					message: '',
				};
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
			};
		}
	};

	const signUp = async (values) => {
		try {
			const resp = await apiSignUp(values);
			if (resp.data) {
				const { token } = resp.data;
				dispatch(onSignInSuccess(token));
				if (resp.data.user) {
					dispatch(
						setUser(
							resp.data.user || {
								avatar: '',
								userName: 'Anonymous',
								authority: ['USER'],
								email: '',
							}
						)
					);
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY);
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
				return {
					status: 'success',
					message: '',
				};
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
			};
		}
	};

	const handleSignOut = () => {
		dispatch(onSignOutSuccess());
		dispatch(setUser(initialState));
		navigate(appConfig.unAuthenticatedEntryPath);
	};

	const signOut = async () => {
		await apiSignOut();
		handleSignOut();
	};

	const getMe = async () => {
		const response = await apiGetMe();
		dispatch(
			setUser({
				avatar: response.data.data.profilePicture,
				authority: [response.data.data.role],
				name: response.data.data.name,
				id: response.data.data._id,
				email: response.data.data.email,
			})
		);
		dispatch(onSignInSuccess());
		console.log(response.data.data, 'your response');
	};

	return {
		authenticated: autUser.email && signedIn,
		signIn,
		signUp,
		signOut,
		getMe,
	};
}

export default useAuth;
